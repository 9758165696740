<template>
  <v-container v-if="usuario">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          voltar
          dark
          icon="person_pin"
          :title="$tc('global.add') + ' ' + $tc('pages.usuario')"
        />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-8">
          <v-form v-model="valid" ref="form" v-if="!loading">
            <v-row>
              <v-col cols="12" md="4" v-if="isAdmin">
                <v-card
                  outlined
                  height="40px"
                  class="d-flex justify-space-between align-center"
                >
                  <v-card-text class="body-1 py-0"
                    >{{ $tc("columns.adm") }}:</v-card-text
                  >
                  <v-switch
                    class="ma-0"
                    inset
                    hide-details
                    :color="usuario.is_admin ? 'secondary' : 'success'"
                    v-model="usuario.is_admin"
                  ></v-switch>
                </v-card>
              </v-col>

              <v-col cols="12" :md="isAdmin ? '8' : '12'">
                <v-text-field
                  v-model="usuario.email"
                  :rules="[rules.email, rules.required]"
                  label="Email"
                  type="email"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model.lazy="usuario.name"
                  :label="$tc('columns.nomecompleto')"
                  :rules="[rules.required]"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model.lazy="usuario.whats"
                  label="Whats"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model.lazy="usuario.celular"
                  :label="$tc('columns.celular')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :append-icon="show ? 'visibility' : 'visibility_off'"
                  name="senha"
                  :label="$tc('columns.senha')"
                  :rules="[rules.required]"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  dense
                  hide-details
                  outlined
                  v-model="usuario.password"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :append-icon="show ? 'visibility' : 'visibility_off'"
                  name="confirmarSenha"
                  :label="$tc('columns.confirmsenha')"
                  :rules="[rules.required]"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  dense
                  hide-details
                  outlined
                  v-model="usuario.password_confirmation"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>

              <v-btn
                @click.prevent="novoUsuario"
                fixed
                right
                bottom
                fab
                dark
                color="buttons"
                :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
                :disabled="!valid"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </v-row>
          </v-form>
          <Carregando v-else />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <Carregando v-else />
</template>

<script>
import { postUsuario } from "@/services/api/usuarios.api.js";
import { mapState } from "vuex";

export default {
  name: "NovoUsuario",
  data: () => ({
    usuario: {
      is_admin: false,
    },
    show: false,
    valid: false,
    loading: false,
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => v.length >= 3 || "Minimo 4 caracteres",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "O e-mail precisa ser valido!";
      },
    },
  }),
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
    }),
  },
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    novoUsuario() {
      this.loading = true;
      postUsuario(this.usuario)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.$router.push({ name: "Usuarios" });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
